/*!
 * Variables | WEB
 */

// Body
// =============================================================================

/// Barva textu
/// @type Color
$body-color: #333333;

/// Barva pozadí
/// @type Color
$body-bg: cadetblue;

// Link
// =============================================================================

/// Barva modrá
/// @type Color
$blue: #0054B3;

/// Barva zelená
/// @type Color
$green: #176324;

// Foreground
// =============================================================================

/// Primární barva popředí
/// @type Color
$html: #9b361a; // HTML

/// Sekundární barva popředí
/// @type Color
$css: #09598f; // CSS

/// Terciární barva popředí
/// @type Color
$js: #715014; // JS
