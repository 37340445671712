/*!
 * Global | WEB
 */

html,
body {
    min-height: 100vh;
}

/* "a:not(nav a)" je spravne reseni, ovsem komprimace ho bohuzel chybne prevede na "a:not(nava)" */
main a,
aside a {
    text-decoration: underline;
}

main a,
aside a,
footer a {
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
}

code {
    color: #932759;
}

strong {
    font-weight: 600;
}

pre {
    padding: 0.5rem;
    background-color: #333333;
    color: #ffffff;
    border-radius: 0.25rem;
}

pre strong {
    color: #ffb530;
}

table {
    margin-bottom: 1rem;
}

code {
    padding: 2px 4px;
    background-color: #f9f2f4;
    border-radius: 0.25rem;
}

iframe {
    margin-bottom: 0.5rem;
}

.img-fluid {
    width: 100%;
    /* IE11 */
}

.jumbotron {
    margin-bottom: 1rem;
}

.figure-caption {
    text-align: center;
}

.r16by9,
.r16by10 {
    width: 100%;
    height: 0;
    padding-bottom: 56.3%;
}

.r16by10 {
    /* background-color: #eee; */
    padding-bottom: 62.5%;
}

.r16by9 .img-fluid,
.r16by10 .img-fluid {
    width: 100%;
    height: auto;
}

.header {
    color: #ffffff;
    text-shadow: 2px 2px 2px black;
}


// Layout
@media (max-width: 575.98px) {

    .navbar,
    .main {
        border-radius: 0 !important;
    }
}

// Decoration
/*.page-front {
    background-image: url("../assets/image/resize/webdesign-sm.jpg");
    background-size: cover;
}*/
@media (min-width: 576px) {
    .page-front {
        background-image: url("../assets/image/resize/webdesign-md.jpg");
        background-size: cover;
    }
}

@media (min-width: 768px) {
    .page-front {
        background-image: url("../assets/image/resize/webdesign-lg.jpg");
    }
}

@media (min-width: 992px) {
    .page-front {
        background-image: url("../assets/image/resize/webdesign-xl.jpg");
    }
}

.page-html {
    background-color: $html;
    background: linear-gradient(135deg, $html 20%, adjust-hue($html, 10%) 80%);

    a {
        color: $html;
    }
}

.page-css {
    background-color: $css;
    background: linear-gradient(135deg, $css 20%, adjust-hue($css, 10%) 80%);

    a {
        color: $css;
    }
}

.page-js {
    background-color: $js;
    background: linear-gradient(135deg, $js 20%, adjust-hue($js, 10%) 80%);

    a {
        color: darken($js, 15%);
        //text-shadow: 1px 1px 1px black;
    }
}

// Footable
.surname {
    background-color: #eee;
}

// Mermaid
.mermaid {
    background-color: white;
}

// Bootstrap
.nav-tabs .nav-link.active {
    color: white;
    background-color: #375c5d;
}
.thead-dark .text-info {
    color: #21e1ff !important;
}
.thead-dark a.text-info:hover {
    color: white !important;
}


/*.bg-primary {
//background-color: #007bff;
}
.bg-secondary {
    //background-color: #6c757d;
}
.bg-success {
    //background-color: #28a745;
    background: #B24592;
    background: linear-gradient(-45deg, #F15F79 20%, #B24592 80%);
}
.bg-danger {
    background-color: #dc3545;
}
.bg-warning {
    background-color: #ffc107;
}
.bg-info {
    //background-color: #17a2b8;

    color: #ffffff;
    background-color: #159957;
    background-image: linear-gradient(120deg, #155799, #159957);
}
.bg-light {
    background-color: #f8f9fa;
}
.bg-dark {
    background-color: #343a40;
}
.bg-white {
    background-color: #fff;
}
.bg-transparent {
    background-color: transparent;
}*/

// Filter
.invert-0 {
    filter: invert(0);
}
.invert-20 {
    filter: invert(20%);
}
.invert-50 {
    filter: invert(50%);
}
.invert-80 {
    filter: invert(80%);
}
.invert-100 {
    filter: invert(100%);
}

// CKEditor 4
.cke_toolbar a.cke_button,
.cke_toolbar .cke_button.cke_button_on:hover{
    padding: 9px 10px;
}
.cke_toolbar a.cke_button:hover {
    padding: 8px 9px;
}
.cke_toolbar a.cke_button.cke_button_disabled:hover {
    padding: 9px 10px 9px;
}
.cke_toolbar .cke_combo_text {
    line-height: 36px;
}
.cke_toolbar .cke_combo_arrow {
    margin-top: 16px;
}
